

// put your brand color here 

$titleColor: #141727;
$brand : #2C40CE;
$brand-hover : #435AFF;
$bodyColor: #7B7B80;

.bg-light-gray{
    background-color: #FFF2E5;
}
.bg-gray{
    background-color: #F8F9FF;
} 
.bg-dark {
    background-color: #212437;
} 