#requestDemo {
  min-height: calc(100vh - 85px);
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
  z-index: 999;
  .quoteBg{
    position: absolute;
    right: 10%;
    z-index: 1;
    top: 52px;
    opacity: 0.6;
  }
  .contactFormArea {
    height: 100%;
    background-color: #fff;
    min-height: calc(100vh - 85px);
    position: relative;
    padding: 50px 0px;
    .form__title {
      h3 {
        font-weight: 500;
        font-size: 25px;
        color: #111424;
      }
    }

    .form__box {
      margin-top: 30px;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      padding: 40px 30px;
      width: 90%;

      .inputBox {
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0px;
        }

        label {
          display: block;
          font-weight: 500;
          font-size: 17px;
          color: #404040;
        }

        input {
          width: 100%;
          height: 42px;
          padding: 0px 10px;
          color: #111424;
          font-weight: 500;
          font-size: 16px;
          border: 1px solid #707070;
          border-radius: 5px;
          margin-top: 10px;
        }

        textarea {
          width: 100%;
          height: 115px;
          padding: 10px 10px;
          color: #111424;
          font-weight: 500;
          font-size: 16px;
          border: 1px solid #707070;
          border-radius: 5px;
          margin-top: 10px;
          resize: none;
        }
      }

      .submit__action {
        button {
          background-color: $brand;
          color: #fff;
          border: none;
          font-weight: 500;
          font-size: 17px;
          padding: 14px 40px;
          border-radius: 7px;
          transition: 0.4s;

          &:hover {
            background-color: $brand-hover;
          }
        }
      }
    }
  }
  
  .label-container{
    width: max-content;
    border: 1px solid #707070;
    position: relative;
    flex: inherit !important;
    padding: 4px 40px;
    border-radius: 30px;
    margin: 0 auto;
    display: table;
    &::after{
      content: "\f107";
      position: absolute;
      margin-left: 16px;
      font-family: "Font Awesome 6 Pro"; 
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .datePickerForm {
    height: 100%;
    background-color: #F8B400;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 99999999;
    &::after{
      height: 100%;
      width: 100%;
      content: "";
      position: absolute;
      background-color: #F8B400;
      right: -100%;
      top: 0;
    }
    
    .buttons-container {
      cursor: pointer;
    }

    .week {
      overflow: hidden;
    }

    .week.highlight {
      background: transparent;
    }

    .week .day:first-child,
    .week .day:last-child {}

    /* sunday */
    .week .day:first-child {}

    .day {
      display: inline-block;
      float: left;
      width: 14.28%;
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;
    }

    .day.disabled span {
      color: #AAA;
    }

    /* vertical highlight */
    .week .day.highlight span {
      /*color: blue;*/
    }

    .day.selected span {
      /*background: orange;*/
    }

    .day.today span {
      font-weight: bold;
    }

    .months-container.hidden,
    .weeks-container.hidden {
      display: none;
    }

    .months-wrapper {
      overflow: hidden;
    }

    .months-wrapper .month {
      display: inline-block;
      float: left;
      width: 25%;
      text-align: center;
      cursor: pointer;
    }

    .months-wrapper .month.one-third {
      width: 33.33%;
    }

    .buttons-container {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;
    }

    .buttons-container .label-container {
      display: inline-block;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      text-align: center;
      text-transform: uppercase;
      font-weight: bold;
    }

    .year-dropdown {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
      padding: 0px;
      cursor: pointer;
    }

    .prev-button,
    .next-button {
      display: none;
    }

    .week {
      margin: 10px 0;
    }

    .week.highlight {
      border-radius: 5px;
    }

    .weeks-wrapper.header {
      border-bottom: 1px solid #eee;
    }

    .week .day.header {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px;
    }

    .day span {
      display: inline-block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
      vertical-align: middle;
      font-weight: 500;
      font-size: 13px;
    }

    .day.today span {
      position: relative;
      display: inline-block;
      font-size: 110%;
    }

    /* weekend */
    .week:not(.start-on-monday) .day:first-child,
    .week:not(.start-on-monday) .day:last-child {
      color: #12141E;
    }

    /* sunday */
    .week:not(.start-on-monday) .day:first-child {
      color: #12141E;
    }

    /* start on monday - weekend */
    .week.start-on-monday .day:nth-child(6),
    .week.start-on-monday .day:last-child {
      color: orange;
    }

    /* start on monday - sunday */
    .week.start-on-monday .day:last-child {
      color: red;
    }

    .day.today span::after {
      display: none;
    }

    .day.sunday span {
      color: #12141E;
    }

    .week .day.highlight span {
      color: #12141E;
    }

    .week .day.selected span {
      background: $brand;
      color: white;
    }

    .week .day[disabled="disabled"] span {
      color: #aaa;
      cursor: not-allowed;
    }

    .months-wrapper .month span {
      display: inline-block;
      padding: 10px;
      text-transform: capitalize;
      margin-bottom: 10px;
    }

    .special-buttons {
      text-align: center;
      border-top: 1px solid #eee;
      padding-top: 10px;
    }

    .today-button {
      margin: 0 auto;
      background: transparent;
      border: none;
      padding: 5px;
    }


    .datepickerBox {
      background-color: #fff;
      width: 60%;
      padding: 40px 20px;
      border-radius: 10px;

      .topInfo {
        h4 {
          font-weight: 500;
          font-size: 24px;
        }
        p {
          color: $brand;
          font-size: 16px;
          margin-top: 5px;
        }
      }
      .calendar-container{
        margin-top: 20px;
      }
      .buttons-container{
        margin-bottom: 20px;
        border: none;
      }
      .label-container{
        
      }
    }
  }
}