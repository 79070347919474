@media (min-width: 1200px) and (max-width: 1260px) {
    #hero__baner {
        padding-top: 50px;
        padding-bottom: 100px;

        .banner__image {
            position: relative;
            top: 100px;
        }
    }

    #requestDemo .datePickerForm .datepickerBox {
        width: 80%;
        padding: 40px 20px;
    }

    #requestDemo .quoteBg {
        z-index: -1;
    }

    #requestDemo .contactFormArea .form__box {
        width: 100%;
    }

    .dropdown:hover .dropdown-menu {
        display: block;
        right: auto !important;
        left: 50% !important;
        transform: translate(-50%, 85px) !important;
        top: 0px;
    }

    #freeUnlimited02 {
        .rightContent {
            img {
                width: 100%;
            }
        }
    }
}

@media (min-width: 1261px) and (max-width: 1400px) {
    #hero__baner .content {
        padding-bottom: 100px;
    }
}

@media (min-width: 1200px) and (max-width: 5000px) {
    .dropdown:hover .dropdown-menu {
        display: block;
        right: auto !important;
        left: 50% !important;
        transform: translate(-50%, 85px) !important;
        top: 0px;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    #about__us .new_about_information .bg_box_info .single_inner_box .image_box h4 {
        font-size: 16px;
    }

    #hero__baner_new .banner_content h2 {
        font-size: 28px;
    }

    #hero__baner_new .banner_content p {
        width: 100%;
    }

    #hero__baner_new .banner_content.right p {
        width: 100%;
    }

    #about__us .newLayoutFeatures .leftInfo h3 {
        font-size: 25px;
        line-height: 38px;
    }

    #about__us .newLayoutFeatures .leftInfo p {
        font-size: 15px;
        line-height: 25px;
    }

    .saleswiseNewBanner .left_content h1 {
        br {
            display: none;
        }
    }

    #newFeaturePrice .itemWrapper>.row {
        row-gap: 30px;
    }

    #newFeaturePrice .itemWrapper .singleItem {
        height: 100%;
    }

    #newDeploymentOptions .itemWrapper>.row {
        row-gap: 30px;
    }

    #newDeploymentOptions .itemWrapper .singleItem {
        height: 100%;
    }
}