.range__slider{
    position: relative;
    user-select: none;
    cursor: pointer;
    .slider{
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        -ms-appearance: none;
        width: 100%;
        height: 9px;
        border-radius: .4rem;
        background-color: #E8E8E8;
        cursor: pointer;
        position: relative;
    }
    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        width: 24px;
        height: 24px;
        border: 0;
        border-radius: 50%;
        background-image: url('../img/icons/slider-thumb.png');
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
        z-index: 999999999;
        position: relative;
      }
    .slider__thumb{
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        background-color: $brand;
        transform: translate(-50%,-50%);
    }
    .toltip__range{
        width: 103px;
        height: 76px;
        left: 50%;
        transform: translate(-50%);
        background-color: #fff;
        position: absolute;
        z-index: 1;
        top: -100px;
        border-radius: 9px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid $brand;
        &::before{
            position: absolute;
            content: "";
            left: 50%;
            height: 15px;
            width: 15px;
            background: #fff;
            bottom: -13px;
            transform: rotate(45deg) translateX(-50%);
            border-bottom: 1px solid $brand;
            border-right: 1px solid $brand;
        }
        .toltipValue{
            font-weight: 500;
            font-size: 18px;
            color: #232323;
        }
        .toltipInfo{
            font-size: 14px;
            color: #232323;
        }
    }
    .rangeProgress{
        position: absolute;
        width: 50%;
        height: 9px;
        background-color: $brand;
        border-radius: .4rem;
        top: 50%;
        transform: translateY(-50%);
        z-index: 100;
    }
}