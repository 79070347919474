@media (min-width: 768px) and (max-width: 991px) {
    #header__top .menu__box .dropdown .dropdown-menu-center {
        transform: translate(-36%, 85px) !important;
    }

    #hero__baner {
        padding-top: 20px;

        .banner__image {
            margin-top: 10px;
        }

        .content {
            text-align: center;
            padding-top: 30px;

            h1 {
                width: auto;
                font-size: 37px;
                line-height: 48px;
            }

            h5 {
                font-size: 17px;
                line-height: 26px;
            }

            a {
                font-size: 15px;
            }

            p {
                margin: 0 auto;
                margin-top: 20px;
            }
        }
    }

    .title-xl {
        font-size: 22px;
    }

    #serviceFeatures {
        padding: 28px 0px 20px 0px;
    }

    #serviceFeatures .main__wrapper .item__box {
        margin-bottom: 40px;
    }

    #quoteBox .quoteBoxContent {
        padding-left: 60px;
        padding-right: 60px;
    }

    #quoteBox .quoteBoxContent h3 {
        font-size: 20px;
    }

    #quoteBox .quoteBoxContent p {
        font-size: 14px;
    }

    #testmonial .slider__wrapper {
        padding-top: 40px;
    }

    #testmonial .slider__wrapper .slider__item .partner__image img {
        width: auto !important;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    #howToWork {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    #howToWork .main__wrapper .single__item {
        margin-bottom: 40px;
    }

    .row.requestDemoRow {
        flex-direction: column-reverse;
    }

    #requestDemo .quoteBg {
        display: none;
    }

    #requestDemo .datePickerForm {
        padding: 50px 0px;
    }

    #requestDemo .datePickerForm .datepickerBox {
        width: 70%;
    }

    #requestDemo .contactFormArea .form__box {
        width: 100%;
    }

    .pricing__banner__slider__active .slider__item .slider__container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0px;
        padding: 40px 15px;
    }

    .pricing__banner__slider__active .slider__item .shape__box1,
    .pricing__banner__slider__active .slider__item .shape__box2,
    .pricing__banner__slider__active .slider__item .shape__box3,
    .pricing__banner__slider__active .slider__item .shape__box4,
    .pricing__banner__slider__active .slider__item .shape__box5,
    .pricing__banner__slider__active .slider__item .shape__box6,
    .pricing__banner__slider__active .slider__item .shape__box7,
    .pricing__banner__slider__active .slider__item .shape__box__8 {
        display: none !important;
    }

    .quoteBox2 .quoteBoxContainer {
        padding: 40px !important;
    }

    #deploymentOptions .mainWrapper .itemBox {
        margin-bottom: 30px;
    }

    #about__us .new_about_information .bg_box_info {
        padding-bottom: 40px;
    }

    #about__us .new_about_information .bg_box_info .single_inner_box .image_box {
        margin-bottom: 20px;
    }

    #new_feauter_area .bg_feater_box {
        padding: 30px 30px 0px 30px;
    }

    #new_feauter_area .bg_feater_box .main_wrapper_area .single_item .content_box h3 {
        font-size: 20px;
    }

    #new_feauter_area .bg_feater_box .main_wrapper_area .single_item .content_box p {
        width: 100%;
    }

    #new_cta_area .bg_box_area .left_content {
        padding-top: 0px;
        text-align: center;
    }

    #new_cta_area .bg_box_area .right_image_box {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }

    #new_cta_area .bg_box_area::after {
        height: 50%;
    }

    #new2quoteBox .quoteBoxContent {
        padding-left: 60px;
        padding-right: 60px;
    }

    #new2quoteBox .quoteBoxContent h3 {
        font-size: 20px;
    }

    #new2quoteBox .quoteBoxContent p {
        font-size: 14px;
    }

    #testmonialNew .slider__wrapper {
        padding-top: 40px;
    }

    #testmonialNew .slider__wrapper .slider__item .partner__image img {
        width: auto !important;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    #howToWorkNew {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    #howToWorkNew .main__wrapper .single__item {
        margin-bottom: 40px;
    }

    #new_1_about .newAboutWrapper {
        padding: 40px 50px;
    }

    #new_1_about .newAboutWrapper .rightContent {
        margin-top: 40px;
    }

    #new_1_about_2 .row.align-items-center {
        flex-direction: column-reverse;
    }

    #new_1_about_2 .sec_heading h3 {
        text-align: center;
    }

    #new_1_about_2 {
        .rightImage {
            text-align: center;

            img {
                width: 70%;
            }
        }

        .itemWrapper {
            display: flex;
            flex-wrap: wrap;
            margin-top: 40px;

            .singleItem {
                flex: 1 50%;
            }
        }
    }

    #newFeaturePrice .itemWrapper>.row {
        row-gap: 20px;
    }

    #newFeaturePrice .itemWrapper .singleItem {
        height: 100%;
    }

    #newFeaturePrice .itemWrapper .singleItem .content h4 {
        font-size: 20px;
    }

    #newFeaturePrice .itemWrapper .singleItem .content p {
        font-size: 15px;
        line-height: 24px;
        width: 100%;
    }

    .newIntregationArea .innerWrapper {
        padding: 50px 50px;
    }

    .newIntregationArea .innerWrapper .sec_title p {
        width: 91%;
    }

    #newDeploymentOptions .itemWrapper>.row {
        row-gap: 25px;
    }

    #newDeploymentOptions .itemWrapper .singleItem {
        height: 100%;
    }

    #new_1_about {
        padding: 60px 0px;
    }

    #new_1_about_2 {
        padding: 0px 0px 60px 0px;
    }

    #newFeaturePrice {
        padding: 60px 0px;
    }

    .newIntregationArea {
        padding: 0px 0px 60px 0px;
    }

    #newDeploymentOptions {
        padding: 0px 0px 60px 0px;
    }
}