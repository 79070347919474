#mobileMenu{
    display: none;
    background-color: #fff;
    padding: 20px 0px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.16);
    position: relative;
    z-index: 9999999;
    .mobileMenuToggle{
        button{
            border: none;
            background-color: transparent;
            font-size: 25px;
        }
    }
}
#mobileMenuContent{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #F8B400;
    z-index: 9999999999999999999;
    left: -100%;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease-in-out;
    overflow: scroll;
    padding: 50px 0px; 
    .menuClose{
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: transparent;
        border: none;
        font-size: 40px;
        color: #fff;
    }
    .MenuContent{
        height: 100%;
        padding: 30px 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        nav{
            width: 100%;
            ul{
                li{
                    display: block;
                    margin-bottom: 20px;
                    &:last-child{
                        margin-bottom: 0px;
                    }
                    a{
                        font-weight: 700;
                        font-size: 20px;
                        color: $titleColor;
                        // text-align: center;
                        display: block;
                    }
                }
                .subMenuLink{
                    position: relative;
                    &::after{
                        content: "\2b";
                        font-family: "Font Awesome 6 Pro";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                        font-size: 25px;
                    }
                }
                .subMenuLink[aria-expanded="true"]{
                    &::after{
                        content: "\f068";
                        font-size: 17px;
                    }
                }
            }
            .spMenu{
                flex: 1;
                margin-top: 30px;
                a{
                    display: block;
                    width: 100%;
                    background-color: #fff;
                    padding: 17px 20px;
                    border-radius: 5px;
                    text-align: center;
                    font-weight: 700;
                    font-size: 15px;
                    text-transform: uppercase;
                    color: $titleColor;
                }
            }
        }
        .subMenuWrapper{
            background-color: rgba(255,255,255,0.1);
            padding: 15px 20px;
            margin: 7px 0px;
            border-radius: 5px;
            ul{
                li{
                    margin-bottom: 15px;
                    a{
                        font-size: 15px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                }
            }
        }
    }
}
#mobileMenuContent.toggle{
    opacity: 1;
    visibility: visible;
    left: 0;
}