#hero__baner {
    background-color: #F8B400;
    padding-top: 100px;

    .content {
        padding-top: 0px;

        h1 {
            font-size: 50px;
            font-weight: 700;
            color: #fff;
            line-height: 65px;
        }

        h5 {
            margin: 30px 0px;
            font-weight: 500;
            font-size: 20px;
            color: #fff;
            line-height: 32px;
        }

        a {
            display: inline-block;
            background-color: #fff;
            font-weight: 700;
            font-size: 18px;
            color: $titleColor;
            padding: 14px 25px;
            min-width: 176px;
            text-align: center;
            border-radius: 5px;

            &:hover {
                background-color: #212437;
                color: #fff;
            }
        }

        p {
            margin-top: 35px;
            width: max-content;
            background-color: #FFCD47;
            color: #272727;
            padding: 7px 10px;
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            border-radius: 5px;

            i {
                margin-right: 5px;
            }
        }
    }
}

// about us area
#about__us {
    padding: 70px 0px;
    background-color: #fff;

    .brand__area {
        margin-top: 60px;

        .brand__item {
            text-align: center;
            display: flex;
            align-items: center;
            min-height: 100px;
            flex: 1;
            min-width: 138px;

            a {
                display: block;
                width: 100%;
            }

            img {
                width: 200px !important;
                max-height: 80px;
                transition: 0.5s;
                margin: 0 auto 0 20px;
                display: table;
                margin-left: 20px;
                object-fit: contain;
            }

            &:hover {
                img {
                    opacity: 1;
                }
            }
        }
    }

    .new_about_information {
        margin-top: 80px;

        .bg_box_info {
            margin-top: 45px;
            background-color: #2B2F44;
            padding: 60px;
            border-radius: 10px;

            .single_inner_box {
                img {
                    width: 100%;
                }

                .image_box {
                    position: relative;

                    h4 {
                        position: absolute;
                        top: 50%;
                        left: 57%;
                        width: 100%;
                        transform: translateY(-50%) translateX(-50%);
                        text-align: center;
                        font-size: 19px;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .bottom__about {
        margin-top: 80px;

        p {
            margin: 0 auto;
            margin-top: 15px;
            font-size: 16px;
            color: #7B7B80;
            width: 80%;
        }
    }

    .newLayoutFeatures {
        margin-top: 30px;
        background-color: #0E172A;
        padding: 50px 50px;
        border-radius: 30px;

        .leftInfo {
            padding-right: 50px;

            h3 {
                color: #fff;
                font-weight: 700;
                font-size: 28px;
                line-height: 42px;
            }

            p {
                margin-top: 20px;
                color: #94A3B8;
                font-size: 17px;
                line-height: 27px;
                width: 84%;
            }
        }

        .rightInfo {
            position: relative;
            z-index: 1;

            &::before {
                position: absolute;
                height: 100%;
                content: "";
                top: 0;
                left: 20px;
                border: 1px dashed #fff;
                border-radius: 30px;
                z-index: -1;
            }

            .singleItem {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                column-gap: 20px;
                flex-wrap: wrap;
                margin-bottom: 40px;

                &:last-child {
                    margin-bottom: 0px;
                }

                .number {
                    height: 44px;
                    width: 44px;
                    background-color: #fff;
                    border-radius: 30px;
                    text-align: center;
                    line-height: 44px;
                    font-weight: 700;
                    font-size: 17px;
                    color: #252436;
                }

                .content {
                    flex: 1;
                    background-color: #fff;
                    height: 44px;
                    padding: 0px 10px;
                    text-align: center;
                    line-height: 44px;
                    border-radius: 30px;

                    p {
                        font-weight: 700;
                        font-size: 17px;
                        color: #252436;
                    }

                }
            }
        }
    }


}

// serviceFeatures
#serviceFeatures {
    padding: 28px 0px 100px 0px;
    background-color: #FCFDFF;

    .container {
        position: relative;
        z-index: 1;
    }

    .shape__01 {
        position: absolute;
        top: 0;
        z-index: -1;
        left: 70px;
    }

    .shape__02 {
        position: absolute;
        top: 50%;
        z-index: -1;
        left: 48%;
        transform: translate(-50%, -50%);
    }

    .shape__03 {
        position: absolute;
        top: 40px;
        z-index: -1;
        right: 20px;
    }

    .top__title {
        a {
            color: $brand;
            text-decoration: underline;
            font-weight: 500;
            font-size: 24px;
        }
    }

    .main__wrapper {
        margin-top: 45px;

        .item__box {
            padding: 36px 25px;
            background-color: #fff;
            border-radius: 20px;
            box-shadow: 0px 5px 10px rgba(44, 64, 206, 0.11);

            .top__service {
                min-height: 188px;

                h4 {
                    margin-top: 20px;
                    font-weight: 700;
                    font-size: 18px;
                    color: #262627;
                }

                p {
                    margin-top: 10px;
                    font-size: 15px;
                    color: #3B3C40;
                    line-height: 25px;
                }
            }

            .feature__box {
                margin-top: 70px;
                min-height: 232px;

                h6 {
                    color: #9C9EA8;
                    font-size: 15px;
                }

                ul {
                    margin-top: 15px;

                    li {
                        display: flex;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 25px;
                        color: #3B3C40;
                        margin-bottom: 16px;

                        .check__icon {
                            min-width: 30px;
                        }

                        &:last-child {
                            margin-bottom: 0px !important;
                        }
                    }
                }
            }

            .actions__box {
                a {
                    display: inline-block;
                    background-color: $brand;
                    color: #fff;
                    padding: 15px 30px;
                    font-weight: 500;
                    border-radius: 8px;

                    img {
                        margin-left: 4px;
                    }

                    &:hover {
                        background-color: $brand-hover;
                    }
                }
            }
        }
    }
}

// quoteBox
#quoteBox {
    .quoteBoxContainer {
        background-color: $brand;
        border-radius: 10px;
        padding: 60px 0px;
        position: relative !important;
        z-index: 1;
        overflow: hidden;
    }

    .shape__04 {
        position: absolute;
        top: -100px;
        left: -100px;
        z-index: -1;
    }

    .shape__05 {
        position: absolute;
        bottom: -100px;
        right: -100px;
        z-index: -1;
    }

    .quoteBoxContent {
        h3 {
            color: #fff;
            font-weight: 700;
            font-size: 28px;
        }

        p {
            color: #fff;
            margin-top: 10px;
            font-size: 15px;
        }

        a {
            margin-top: 30px;
            display: inline-block;
            background-color: #fff;
            color: $brand;
            font-weight: 500;
            padding: 13px 30px;
            min-width: 161px;
            border-radius: 8px;

            &:hover {
                opacity: 0.7;
            }
        }
    }
}

// testmonial
#testmonial {
    padding: 90px 0px;

    .slider__wrapper {
        margin-top: 50px;
        background-color: #FFF2E5;
        padding: 90px 0px 40px 0px;

        .testmonial__slider__active {
            .owl-dots {
                text-align: center;
                margin-top: 20px;

                .owl-dot {
                    height: 10px;
                    width: 10px;
                    background-color: #FFDBB2;
                    border-radius: 30px;
                    margin: 0px 5px;
                    transition: 0.4s;
                }

                .active {
                    width: 44px;
                }
            }
        }

        .slider__item {
            .partner__image {
                img {
                    width: auto !important;
                    margin: 0 auto;
                }
            }

            .contentBox {
                p {
                    font-size: 20px;
                    line-height: 35px;
                    color: #252436;
                }

                h4 {
                    margin-top: 30px;
                    font-weight: 700;
                    font-size: 20px;
                    color: #252436;
                }

                h6 {
                    color: #252436;
                    font-size: 15px;
                    font-weight: 400;
                    margin-top: 8px;
                }
            }
        }
    }
}

// howToWork
#howToWork {
    padding: 70px 0px;
    background-color: #F8F9FF;

    .main__wrapper {
        margin-top: 40px;

        .single__item {
            .numberBox {
                border: 2px solid $brand;
                height: 88px;
                width: 88px;
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                font-size: 32px;
                color: $brand;
                border-radius: 50%;
            }

            h3 {
                margin-top: 30px;
                font-size: 22px;
                color: #252436;
                font-weight: 700;
            }

            p {
                font-size: 18px;
                color: #3B3C40;
                margin-top: 8px;
            }
        }
    }
}

// subscribeBox
#subscribeBox {
    padding: 80px 0px;
    background-color: #fff;

    .subsform {
        margin-top: 40px;
        display: flex;
        justify-content: center;

        .input__box {
            width: 465px;

            input {
                width: 100%;
                height: 50px;
                border-radius: 10px;
                border: 1px solid #707070;
                padding: 0px 20px;
                color: #44464A;
            }
        }

        .submit__box {
            margin-left: 30px;

            button {
                background-color: $brand;
                color: #fff;
                font-weight: 700;
                font-size: 15px;
                height: 100%;
                padding: 0px 50px;
                border: none;
                border-radius: 8px;

                &:hover {
                    background-color: $brand-hover;
                }
            }
        }
    }
}

// footerarea
#footer {
    padding: 60px 0px 60px 0px;
    background: #212437;

    .footer_item_wrapper {
        display: flex;
        justify-content: space-between;

        .logo_area {
            margin-right: 80px;

            p {
                color: #C2C2C2;
                font-size: 12px;
                line-height: 20px;
                margin-top: 15px;
            }
        }

        .link_area {
            margin-right: 80px;

            h4 {
                color: #fff;
                font-weight: 500;
                font-size: 16px;
            }

            ul {
                margin-top: 15px;

                li {
                    margin-bottom: 8px;

                    a {
                        color: #C2C2C2;
                        font-size: 14px;

                        &:hover {
                            color: #fff;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .social_link {
            h4 {
                color: #fff;
                font-weight: 500;
                font-size: 16px;
            }

            ul {
                margin-top: 15px;

                li {
                    display: inline-block;
                    margin-right: 20px;

                    a {
                        color: #fff;

                        &:hover {
                            color: $brand;
                        }
                    }
                }
            }
        }
    }
}