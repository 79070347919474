@media (max-width: 575px) {
    #header__top .menu__box .dropdown .dropdown-menu-center {
        transform: translate(-36%, 85px) !important;
    }

    #hero__baner {
        padding-top: 20px;

        .banner__image {
            margin-top: 10px;
        }

        .content {
            text-align: center;
            padding-top: 30px;

            h1 {
                width: auto;
                font-size: 29px;
                line-height: 40px;
            }

            h5 {
                font-size: 17px;
                line-height: 26px;
            }

            a {
                font-size: 15px;
                width: 91%;
                margin-bottom: 13px;
            }

            p {
                width: auto;
                margin: 0 auto;
                margin-top: 20px;
            }
        }
    }

    #about__us .brand__area {
        margin-top: 30px;
    }

    .title-xl {
        font-size: 18px;
        line-height: 28px;
    }

    #about__us {
        padding: 40px 0px;
    }

    #serviceFeatures {
        padding: 28px 0px 20px 0px;
    }

    #about__us .bottom__about {
        margin-top: 20px;
    }

    #serviceFeatures .main__wrapper .item__box {
        margin-bottom: 40px;
        width: 100%;
    }

    #quoteBox .quoteBoxContent {
        padding-left: 20px;
        padding-right: 20px;
    }

    #quoteBox .quoteBoxContent h3 {
        font-size: 20px;
    }

    #quoteBox .quoteBoxContent p {
        font-size: 14px;
    }

    #testmonial .slider__wrapper {
        padding-top: 40px;
    }

    #testmonial .slider__wrapper .slider__item .partner__image img {
        width: 60% !important;
        margin: 0 auto;
        margin-bottom: 30px;
    }

    #howToWork {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    #howToWork .main__wrapper .single__item {
        margin-bottom: 40px;
    }

    #header__top {
        display: none;
    }

    #footer {
        padding: 50px 0px 70px 0px;
    }

    #footer .footer_item_wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    #about__us .bottom__about p {
        width: 100%;
    }

    #serviceFeatures .top__title h5 {
        font-size: 19px;
    }

    #serviceFeatures .main__wrapper {
        margin-top: 25px;
    }

    #testmonial {
        padding: 30px 0px;
    }

    #testmonial .slider__wrapper .slider__item .contentBox p {
        font-size: 16px;
        line-height: 28px;
    }

    #testmonial .slider__wrapper .slider__item .contentBox h4 {
        font-size: 17px;
    }

    #testmonial .slider__wrapper .slider__item .contentBox h6 {
        font-size: 14px;
        margin-top: 8px;
    }

    #howToWork .main__wrapper .single__item h3 {
        margin-top: 20px;
        font-size: 17px;
    }

    #howToWork .main__wrapper .single__item p {
        font-size: 16px;
    }

    #subscribeBox .subsform {
        flex-direction: column;
    }

    #subscribeBox .subsform .input__box {
        width: 100%;
    }

    #subscribeBox .subsform .submit__box {
        margin-left: 0px;
    }

    #subscribeBox .subsform .submit__box button {
        margin-top: 20px;
        font-size: 15px;
        padding: 15px 0px;
        display: block;
        width: 100%;
    }

    #footer .footer_item_wrapper .logo_area {
        margin-bottom: 30px;
    }

    #footer .footer_item_wrapper .link_area {
        margin-bottom: 30px;
    }

    #subscribeBox {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .row.requestDemoRow {
        flex-direction: column-reverse;
    }

    #requestDemo .quoteBg {
        display: none;
    }

    #requestDemo .datePickerForm {
        padding: 30px 20px;
    }

    #requestDemo .datePickerForm .datepickerBox {
        width: 100%;
    }

    #requestDemo .contactFormArea .form__box {
        width: 100%;
    }

    .pricing__banner__slider__active .slider__item .slider__container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0px;
        padding: 40px 15px;
    }

    .pricing__banner__slider__active .slider__item .shape__box1,
    .pricing__banner__slider__active .slider__item .shape__box2,
    .pricing__banner__slider__active .slider__item .shape__box3,
    .pricing__banner__slider__active .slider__item .shape__box4,
    .pricing__banner__slider__active .slider__item .shape__box5,
    .pricing__banner__slider__active .slider__item .shape__box6,
    .pricing__banner__slider__active .slider__item .shape__box7,
    .pricing__banner__slider__active .slider__item .shape__box__8 {
        display: none !important;
    }

    #PricingMain .pricingMainWrapper .pricing__box .price__item {
        margin-bottom: 30px;
    }

    .pricing__banner__slider__active .slider__item .content h1 {
        font-size: 22px;
        line-height: 33px;
        margin-top: 30px;
    }

    .pricing__banner__slider__active .slider__item .content h5 {
        max-width: 100%;
        font-size: 14px;
        line-height: 23px;
    }

    #PricingMain {
        padding: 50px 0px;
    }

    #PricingMain .pricingHeadingInfo h4 {
        font-size: 31px;
    }

    #PricingMain .pricingHeadingInfo h5 {
        margin-top: 6px;
        font-size: 15px;
    }

    #PricingMain .pricingMainWrapper {
        margin-top: 30px;
    }

    #PricingMain .pricingMainWrapper .top__title p {
        font-size: 16px;
    }

    #quoteBox .quoteBoxContent {
        padding-left: 30px;
        padding-right: 30px;
    }

    .quoteBox2 .quoteBoxContainer {
        padding: 30px !important;
    }

    #deploymentOptions .mainWrapper .itemBox {
        width: 100% !important;
        min-height: 350px;
        margin-bottom: 30px;
    }

    #blogFeatures {
        padding: 50px 0px 50px 0px;
    }

    #freeUnlimitedArea {
        padding: 50px 0px 50px 0px;
    }

    #deploymentOptions {
        padding-top: 0px;
    }

    #deploymentOptions .top__title h3 {
        font-size: 26px;
    }

    #freeUnlimited02 .rightContent img {
        width: 80% !important;
        margin: 0 auto;
        margin-top: 30px;
    }

    #freeUnlimited03 {
        padding-top: 50px;
        overflow: hidden;

        .imageBox {
            margin-top: 30px;
            margin-bottom: 30px;

            img {
                transform: scale(2);
            }
        }
    }

    #freeUnlimited02 {
        padding-top: 50px;
    }

    .brand__area .owl-dots {
        text-align: center;
        margin-top: 0px !important;
    }

    #about__us .new_about_information .bg_box_info {
        padding: 20px;
        margin-top: 20px;
    }

    #about__us .new_about_information {
        margin-top: 20px;
    }

    #about__us .new_about_information .bg_box_info .single_inner_box .image_box h4 {
        left: 54%;
        font-size: 17px;
    }

    #new_feauter_area .bg_feater_box {
        padding: 20px;
    }

    #new_feauter_area .bg_feater_box .top_heading_area p {
        width: 100%;
    }

    #new_feauter_area .bg_feater_box .main_wrapper_area .single_item .content_box {
        margin-top: 20px;
    }

    #new_feauter_area .bg_feater_box .main_wrapper_area .single_item .content_box h3 {
        font-size: 21px;
    }

    #new_feauter_area .bg_feater_box .main_wrapper_area .single_item .content_box p {
        font-size: 15px;
        width: 100%;
    }

    #new_cta_area .bg_box_area .left_content {
        padding-top: 0px;
        text-align: center;
    }

    #new_cta_area .bg_box_area .right_image_box {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }

    #new_cta_area .bg_box_area::after {
        height: 50%;
    }

    #new_cta_area .bg_box_area {
        position: relative;
        z-index: 1;
        padding: 0px 15px;
    }

    #new_features_2_area .main_wrapper .single_item {
        margin-bottom: 40px;
    }

    #new_cta_area .bg_box_area .left_content h4 {
        font-size: 18px;
    }

    #new_cta_area .bg_box_area .left_content p {
        font-size: 17px;
        line-height: 25px;
    }

    #new_cta_area {
        padding-bottom: 20px;
    }

    #new_feauter_area .bg_feater_box .main_wrapper_area .single_item .icon_box {
        img {
            height: 30px;
        }
    }

    #about__us .new_about_information .bg_box_info .single_inner_box .image_box h4 {
        font-size: 13px;
        width: 70%;
        line-height: 17px;
    }

    #about__us .new_about_information .bg_box_info .single_inner_box {
        margin-bottom: 10px;
    }

    #hero__baner_new .leftShape {
        height: 200px;
        width: 200px;
        right: -100px;
    }

    #hero__baner_new .rightShape {
        height: 200px;
        width: 200px;
        right: -100px;
        bottom: -100px;
    }

    #about__us .newLayoutFeatures {
        padding: 20px 20px;
    }

    #about__us .newLayoutFeatures .leftInfo h3 {
        font-size: 18px;
        line-height: 28px;
    }

    #about__us .newLayoutFeatures .leftInfo {
        padding-right: 0px;
    }

    #about__us .newLayoutFeatures .leftInfo p {
        font-size: 14px;
        line-height: 23px;
    }

    #about__us .newLayoutFeatures .rightInfo .singleItem .content p {
        font-size: 13px;
    }

    #about__us .newLayoutFeatures .rightInfo .singleItem .number {
        font-size: 13px;
    }

    #about__us .newLayoutFeatures .rightInfo .singleItem {
        margin-bottom: 22px;
    }

    #new2quoteBox .quoteBoxContent a {
        width: 100%;
    }

    #testmonialNew .slider__wrapper .testmonial__slider__active_new {
        padding: 30px 20px;
    }

    #testmonialNew .slider__wrapper .slider__item .partner__image img {
        width: 200px !important;
        height: 200px !important;
        margin: 0 auto;
        margin-bottom: 10px;
    }

    #testmonialNew .slider__wrapper .slider__item .contentBox p {
        font-size: 14px;
        line-height: 24px;
    }

    #testmonialNew .slider__wrapper .testmonial__slider__active_new .owl-nav {
        position: relative;
        right: 0px;
        bottom: 0px;
    }

    #testmonialNew .slider__wrapper .testmonial__slider__active_new .owl-nav .owl-prev,
    #testmonialNew .slider__wrapper .testmonial__slider__active_new .owl-nav .owl-next {
        margin-left: 0px;
        margin-right: 10px;
    }

    #howToWorkNew {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    #howToWorkNew .main__wrapper .single__item {
        margin-bottom: 40px;
    }

    #howToWorkNew .main__wrapper .single__item h3 {
        margin-top: 20px;
        font-size: 17px;
    }

    #howToWorkNew .main__wrapper .single__item p {
        font-size: 16px;
    }

    #subscribeBoxNew .contentBox {
        background-color: #0E172A;
        border-radius: 30px;
        padding: 30px 20px;
    }

    #subscribeBoxNew .subsform {
        flex-direction: column;
    }

    #subscribeBoxNew .subsform .input__box {
        width: 100%;
    }

    #subscribeBoxNew .subsform .submit__box {
        margin-left: 0px;
    }

    #subscribeBoxNew .subsform .submit__box button {
        margin-top: 20px;
        font-size: 15px;
        padding: 15px 0px;
        display: block;
        width: 100%;
    }

    #subscribeBoxNew {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    #hero__baner_new .banner_content {
        padding: 60px 0px;
    }

    #hero__baner_new .banner_content h2 {
        font-size: 21px;
        line-height: 22px;
    }

    #hero__baner_new .banner_content p {
        margin-top: 11px;
        font-size: 14px;
        line-height: 24px;
        width: 100%;
    }

    #hero__baner_new .banner_content a {
        font-size: 14px;
        padding: 12px 13px;
        width: 100%;
    }

    #hero__baner_new .leftShape {
        height: 200px;
        width: 200px;
        right: -100px;
    }

    #hero__baner_new .rightShape {
        height: 200px;
        width: 200px;
        right: -100px;
        bottom: -100px;
    }

    #new2quoteBox .quoteBoxContainer {
        padding: 40px 35px;
        max-width: 93%;
    }

    #new2quoteBox .quoteBoxContent h3 {
        font-size: 21px;
        line-height: 27px;
    }

    .saleswiseNewBanner .left_content h1 {
        br {
            display: none;
        }
    }

    .saleswiseNewBanner .left_content h1 {
        font-size: 31px;
        line-height: 44px;
    }

    .saleswiseNewBanner .left_content p {
        font-size: 17px;
        margin-bottom: 25px;
        line-height: 28px;
    }

    .saleswiseNewBanner .left_content a,
    .saleswiseNewBanner .left_content button {
        width: 100%;
    }

    #new_1_about .newAboutWrapper {
        padding: 28px 30px;
    }

    #new_1_about .newAboutWrapper .leftInfo h3 {
        font-size: 22px;
    }

    #new_1_about .newAboutWrapper .leftInfo p {
        font-size: 15px;
        line-height: 25px;
    }

    #new_1_about .newAboutWrapper .rightContent .second {
        border-left: 2px solid #6EA9D3;
    }

    #new_1_about .newAboutWrapper .rightContent .third {
        border-bottom: 2px solid #6EA9D3;
    }

    #new_1_about .newAboutWrapper .rightContent .forth {
        border-left: 2px solid #6EA9D3;
    }

    #new_1_about_2 .sec_heading h3 {
        font-size: 25px;
        line-height: 35px;
    }

    #new_1_about_2 .rightImage img {
        width: 100%;
    }

    #new_1_about_2 .itemWrapper .singleItem .content p {
        font-size: 15px;
        line-height: 25px;
        width: 100%;
    }

    #new_1_about_2 .itemWrapper .singleItem {
        margin-bottom: 45px;
    }

    #new2quoteBox.typoChange .quoteBoxContent h3 {
        font-size: 25px;
        line-height: 38px;
    }

    #new2quoteBox.typoChange .quoteBoxContent p {
        font-size: 17px;
    }

    #newFeaturePrice .secHeading h4 {
        font-size: 28px;
    }

    #newFeaturePrice .secHeading p {
        font-size: 17px;
        line-height: 26px;
    }

    #newFeaturePrice .itemWrapper .singleItem .content h4 {
        font-size: 20px;
    }

    .newIntregationArea .innerWrapper {
        padding: 30px 30px;
    }

    #newDeploymentOptions .secHeading h4 {
        font-size: 28px;
    }

    #newDeploymentOptions .secHeading p {
        font-size: 17px;
        line-height: 26px;
    }

    #new_1_about {
        padding: 40px 0px;
    }

    #new_1_about_2 {
        padding: 0px 0px 40px 0px;
    }

    #newFeaturePrice {
        padding: 40px 0px;
    }

    .newIntregationArea {
        padding: 0px 0px 40px 0px;
    }

    #newDeploymentOptions {
        padding: 0px 0px 40px 0px;
    }

    #new_1_about_2 .sec_heading {
        margin-bottom: 30px;
    }

    #newFeaturePrice .secHeading {
        margin-bottom: 40px;
    }

    .newIntregationArea .innerWrapper .sec_title {
        margin-bottom: 30px;
    }

    #newDeploymentOptions .secHeading {
        margin-bottom: 40px;
    }

    #new_1_about .newAboutWrapper .rightContent .first {
        border-right: none;
        border-bottom: 2px solid #6EA9D3;
    }

    #new_1_about .newAboutWrapper .rightContent .second {
        border-bottom: 2px solid #6EA9D3;
        border-left: none;
    }

    #new_1_about .newAboutWrapper .rightContent .third {
        border-right: none;
    }

    #new_1_about .newAboutWrapper .rightContent .forth {
        border-left: none;
    }

}